<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-account-details</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          GESPEICHERTE PERSONEN
        </h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          :fab="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          to="/verein/erstelle-person"
        >
          <v-icon class="mr-2">mdi-account-plus</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Neue Person</span>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          :fab="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          to="/verein/personen/csvimport"
        >
          <v-icon class="mr-2">mdi-database-plus</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">CSV-Import</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-row justify="center" v-if="benutzer.length > 0">
        <v-col cols="11" v-for="m in benutzer" :key="m.id">
          <v-card
            class="rounded-xl"
            :color="template.colors.blocks"
            :light="!template.isDark(template.colors.blocks)"
            :dark="template.isDark(template.colors.blocks)"
          >
            <v-row class="px-4">
              <v-col cols="auto" align-self="center">
                <v-icon>mdi-account</v-icon>
              </v-col>
              <v-col align-self="center">
                <h3>
                  {{ m.vorname }} {{ m.name }}
                  <span
                    class="font-weight-light ml-2"
                    v-if="m.aktivitaet && m.aktivitaet != 'Zuschauer'"
                  >
                    {{ m.aktivitaet }}
                  </span>
                </h3>
              </v-col>
              <v-col cols="auto" align-self="center" v-if="m.signature">
                <v-btn
                  icon
                  @click="
                    showinfo = true
                    signature = m.signature
                  "
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-btn
                  v-if="confirm_delete == m.id"
                  :loading="deleting == m.id"
                  text
                  @click="del()"
                  color="red"
                >
                  LÖSCHEN BESTÄTIGEN
                </v-btn>
                <v-btn
                  v-if="confirm_delete != m.id"
                  icon
                  @click="confirm_delete = m.id"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-col cols="12" v-if="benutzer.length == 0">
        <v-card dark>
          <v-row style="min-height: 30vh;" justify="center">
            <v-col align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine weiteren Benutzer für deinen Verein
                angelegt.
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showinfo"
      :content-class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : ''"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :max-width="$vuetify.breakpoint.mdAndUp ? '600px' : undefined"
    >
      <v-card>
        <v-toolbar :color="template.colors.primary">
          <v-toolbar-title>Datenschutzinformation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              showinfo = ''
              signature = ''
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row justify="center" class="mt-3">
          <v-col cols="11">
            <span class="font-weight-bold">
              Mit meiner Unterschrift bestätige ich, dass ich die
              <a
                :href="
                  template.datenschutz
                    ? template.datenschutz.link
                    : '/datenschutz'
                "
                target="_blank"
              >
                Datenschutzinformationen
              </a>
              zur Kenntnis genommen habe.
            </span>
          </v-col>
          <v-col cols="12">
            <v-card light class="rounded-b-xl">
              <v-img :src="signature"></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      benutzer: [],
      generating: false,
      deleting: '',
      confirm_delete: '',
      showinfo: '',
      signature: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Personen')
      .onSnapshot((snap) => {
        this.benutzer = []
        snap.forEach((doc) => {
          this.benutzer.push(doc.data())
          var index = this.benutzer.length - 1
          this.benutzer[index].id = doc.id
        })
        console.log(this.benutzer)
      })
  },
  methods: {
    addNull(val) {
      if (val > 10) {
        return val
      }
      return '0' + val
    },
    del() {
      if (this.confirm_delete) {
        this.deleting = this.confirm_delete
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Personen')
          .doc(this.confirm_delete)
          .delete()
          .then(() => {
            this.deleting = ''
            this.confirm_delete = ''
          })
      }
    },
    load_datum() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('datum', '==', this.datum)
        .get()
        .then((snap) => {
          this.anwesenheit = []
          snap.forEach((doc) => {
            this.anwesenheit.push(doc.data())
            this.anwesenheit[this.anwesenheit.length - 1].id = doc.id
          })
        })
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    generatePDF() {
      if (this.anwesenheit.length > 0) {
        this.generating = true
        this.$refs.Anwesenheit.generatePdf()
        this.$emit('generated', true)
        setTimeout(() => {
          this.generating = false
        }, 1000)
      }
    },
  },
}
</script>
>
